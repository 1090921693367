import React, { useState, useEffect } from 'react';
import { useUrl, Axios_put, Axios_delete, Axios_get, baseURL } from '../pages/external_module';
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import File from '../basic/file_Custom';

import NewNotice from '../component/NewNoticeForm';
import useConfirm from "../Confirm_Modul/useConfirm";

function Notice( ) {
    const url = `web/notice`;
    const { confirm } = useConfirm();

    const { noticelist_button } = useUrl();  // 클릭시 login_url경로로 이동
    const [ notice, setNoticeData ] = useState(null);
    const [ accessUserGrade, setAccessUserGrade ] = useState(false);

    /*
        수정 데이터 관련 변수 선언
        - 수정하지 않은경우 null이 날아감 -> State에서 기본값 설정 핑료
    */
    const [title, setTitle] = useState(notice ? notice.title : '');
    const [content, setContent] = useState(notice ? notice.content : '');
    const [selectedOption, setSelectedOption] = useState(notice ? notice.category_id_id : 1);
    const [selectedFile, setSelectedFile] = useState(notice ? notice.file : null); 

    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);
    const sessionID = cookies["sessionID"];
    /* 언어변경 */
    const [translatedText, setTranslatedText] = useState({});
    const language = cookies.language; 

    const { id } = useParams();


    const navigate = useNavigate();
    useEffect(()=> {
        if (language === '2') {
            const translatedText = {
                'Do you want to edit?' : 'ທ່ານຕ້ອງການແກ້ໄຂບໍ?',
                'Are you sure you want to delete?' :'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ?',
                'YES':'ແມ່ນແລ້ວ',
                'NO':'ບໍ່',
                'DEVICE NOTICE': 'ແຈ້ງການອຸປະກອນ',
                'ADMIN NOTICE':'ແຈ້ງການຜູ້ບໍລິຫານ',
                'POST':'ໂພສ',
                'TITLE':'ຫົວຂໍ້',
                'cancel':'ຍົກເລີກ',
                'Delete':'ລຶບ',
                'Update':'ອັບເດດ',
                'Download':'ດາວໂຫຼດ',
                'FILE':'ໄຟລ໌',
                'File':'ໄຟລ໌',
                'Notice View':'ສັງ​ເກດ​ເບິ່ງ​',
                'Attachment':'ໄຟລ໌ແນບ'
            };
            setTranslatedText(translatedText);
            } else {
            setTranslatedText({});
            }
        fetchData();
    }, [id,language]);

    /*
        페이지 렌더링
    */
    const fetchData = async () => {
        

        const getUrl = url + '/' +`${id}`;
        const result = await Axios_get( getUrl );

        /* 
            하위 등급자가 상위 등급자의 글을 수정/삭제할 수 없도록 버튼 숨김 

            cookies에 담긴 값 sup's'er 으로 오타 있는 상태로 백에서 넘어옴
            추후에 문제 생겼을 때 확인해볼 필요 있음 - zk
        */
            const loginUserGrade = cookies.userGrade;
            const writerGrade = assessGrade(result.is_superuser, result.is_staff);
    
            console.log("Login ", cookies.userGrade, " Writer ", writerGrade);
    
    
            if( loginUserGrade >= writerGrade ) {
                setAccessUserGrade(true);
            } else {
                setAccessUserGrade(false);
            }

        console.log("RESEULT > ", result)
        setNoticeData(result);
        console.log("cookies  > ", cookies);

        /*
            수정한 데이터가 없을 경우 ''로 초기화 되는 문제 방지를 위해
            데이터 불러올 때 초기값 지정
        */
        if (result) {
            setTitle(result.title);
            setContent(result.content);
            setSelectedOption(result.category_id_id);
            setSelectedFile(result.file);
        }


        
    }

    

    /*
        게시글 수정
        - 추후 세션 정보 확인하여, 하위등급의 사용자가 접근하지 못하도록 조건부 렌더링 코드추가 필요
    */
    const updateNotice = async() => {
        const formData = new FormData();

        const userConfirm = await confirm({
            message: translatedText['Do you want to edit?'] || 'Do you want to edit?',
            buttons: {
              ok: translatedText['YES'] || 'YES',
              cancel: translatedText['NO'] || 'NO',
            },
        });

        formData.append("file", selectedFile);
        const context = {
            'notice_id':id,
            "title": title,
            "content": content,
            "category_id_id":selectedOption,
            "view_count" : notice.view_count
        };
        formData.append('test', JSON.stringify(context))
        console.log("context",context)
        // formData.append('context', JSON.stringify(context));
        console.log("!! ", notice);
        
        console.log('id값',id)
        if( userConfirm ){
            console.log('url',url)
            console.log('id',id)
            Axios_put(url, id, formData).then((result)=> {
                if(result !== null) {
                    // 수정 성공시 공지사항 목록페이지로 이동
                    navigate('/noticelist');
                } else {
                    console.log("UPDATE FAILED");
                }
            }).catch((error)=> {
                console.error('Error sending UPDATE request:', error);
            })
        }
    }

    // 수정하고자하는 파일 불러오기 -> 해당 상태 저장
    const handleFileChange = (file_data) => {
        setSelectedFile(file_data); // Update selectedFile state with the selected file
    };

    // select box의 option이 변경될 때마다, 해당 상태 저장
    const handleOptionChange = (e) => {
        setSelectedOption(Number(e.target.value));
    };
  
    /*
        게시글 삭제
        - 추후 세션 정보 확인하여, 하위등급의 사용자가 접근하지 못하도록 조건부 렌더링 코드추가 필요
    */
   const deleteNotice = async() => {
        const userConfirm = await confirm({
            message: translatedText['Are you sure you want to delete?'] || 'Are you sure you want to delete?',
            buttons: {
            ok: translatedText['YES'] || 'YES',
            cancel: translatedText['NO'] || 'NO',
            },
        });
        if( userConfirm ){
            Axios_delete(url, id).then((result) => {
                if( result !== null ) {
                    // 삭제 성공시 공지사항 목록페이지로 이동
                    navigate('/noticelist');
                } else {
                    console.log("DELETE FAILED");
                }
            }).catch((error)=> {
                console.error('Error sending DELETE request:', error);
            });
        }
   }


   /*
        사용자 등급에 따른 접근 제한
        (is_super, is_staff)
        1, 1 => super
        0, 1 => staff
   */
   const assessGrade = ( isSuper, isStaff ) => {
    
        let grade = "";
        if( isSuper === 1 && isStaff === 1) grade = 3;
        else if( isSuper === 0 && isStaff === 1 ) grade = 2;
        else if( isSuper === 0 && isStaff === 0 ) grade = 1;

        return grade;
   }
    
    
    /* 
        새로 등록하는 공지사항일 경우
        - component 폴더에 있는 NewNoticeForm.js 불러옴
    */

   


    if(!notice) {
        return <NewNotice />;
    } else {
        return(
            <div >
                <div className="sub_wrp notice_wrp">
    
                    <h4>{translatedText['Notice View'] || 'Notice View'}</h4>
                    <p className='select_box'>
                        <select name="board_list" id="board_list" onChange={handleOptionChange} defaultValue={notice.category_id_id}>
                            <option value={1}>{translatedText['DEVICE NOTICE'] || 'DEVICE NOTICE'}</option>
                            <option value={2}>{translatedText['ADMIN NOTICE'] || 'ADMIN NOTICE'}</option>
                            <option value={3}>{translatedText['POST'] || 'POST'}</option>
                        </select>
                    </p>
                    <div className='title'>
                        <h5>{translatedText['TITLE'] || 'TITLE'}</h5>
                        <p><input type="text" name="" id="title"  title="title" placeholder="Please enter the subject." defaultValue={notice.title} onChange={(e) => setTitle(e.target.value)} /></p>
                    </div>
                    <p className='textarea_box'>
                        <textarea name="content" id="content" cols="30" rows="10" defaultValue={notice.content}  onChange={(e) => setContent(e.target.value)} placeholder="Please enter your details."></textarea>
                    </p>
                    { notice.file !== null ? (
                    <div className='attachment_wrp'>                    
                        <dl>
                            <dt className='attach_tit'>{translatedText['Attachment'] || 'Attachment'}</dt>
                            <dd>
                            {translatedText['FILE'] || 'FILE'} : 
                                <input type="hidden" name='' id='' defaultValue={notice.file} /><a href={`${baseURL}/download/${notice.file}`}>{notice.file}</a>
                                <button type='button' title='download'><a href={`${baseURL}/download/${notice.file}`}>{translatedText['Download'] || 'Download'}</a></button>
                            </dd>
                        </dl>                    
                    </div>
                    ):(
                        null
                    )}                    
    
                    <div className='data'>
                        <p className='tit'>{translatedText['File'] || 'File'}</p> 
                        <File onFileChange={handleFileChange}/>
                    </div>                    
                    <div className='btnwrp'>                        
                    {
                        /* 본인이 작성한 글 수정 가능, 작성자보다 하위등급인 자는 수정 삭제 불가 */
                        accessUserGrade? (
                        <>
                            <button type="" onClick={updateNotice} title="complete" className='col01'>{translatedText['Update'] || 'Update'}</button>
                            <button type="" onClick={deleteNotice} title="delete" className='col04'>{translatedText['Delete'] || 'Delete'}</button>
                        </>
                            ) : null
                        }     
                        <button type="submit" onClick={noticelist_button} title="cancel" className='col02'>{translatedText['cancel'] || 'cancel'}</button>
                    </div>    
                </div>  
    
            </div> 
        );

    }

}

export default Notice;
